import React, {useState, useCallback, useEffect, useMemo} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import "../assets/css/main.scss";
import 'react-toastify/dist/ReactToastify.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from "moment";
import {ToastContainer, toast} from 'react-toastify';
import {useFormState} from 'react-use-form-state';
import Select from 'react-select';
import {DayPickerSingleDateController as DatePicker, /*SingleDatePicker*/} from "react-dates";
import {
    isTimeUnavailable,
    isDateUnavailable,
    minReservationDate
} from "../utils/reservacion-utils";
import TimePicker from "./TimePicker";
import Footer from "./Footer";
import ReservationSuccess from "./ReservationSuccess";
import Terms from "../Modals/Terms";
import TideEntitySelect from '../components/TideEntitySelect'
import {validateReservation, uploadReservation} from "../utils/reservacion-utils";
import Facebook from "../assets/img/fb.png";
import Twitter from "../assets/img/tw.png";
import Instagram from "../assets/img/in.png";
import queryString from "querystring";
import useSingleDatePickerState from "../utils/hooks/useSingleDatePickerState";


export default function ReservationForm() {
    const [persona, setPersona] = useState({
        name: '',
        patLastName: '',
        matLastName: '',
        email: '',
        phone: '',
        city: '',
    });
    const [adult] = useState([]);
    const [paymentComplete, setPaymentComplete] = useState(false);
    const [comment, setComment] = useState('');
    const [acceso, setAcceso] = useState('');
    const [adultNumber, setAdultNumber] = useState(0);
    const [kidNumber /*, setKidNumber */] = useState(0);
    const [reservation, setReservation] = useState();
    const [loading, setLoading] = useState(false);
    const [showAviso, setShowAviso] = useState(false);
    const birthdayInput = useSingleDatePickerState(moment().year(1990));

    //const [selectedFlights, setSelectedFlights] = useState('');

    const [returnReservationDate, setReturnReservationDate] = useState(moment().add(6,'hours'));
    const [returnReservationDateFocus, setReturnReservationDateFocus] = useState({focused: false});

    const [formState, {checkbox}] = useFormState(null, {withIds: true});


    useEffect(() => {
        const values = queryString.parse(window.location.search);
        if (values['?thanks_payment'] || values['thanks_payment']) {
            setPaymentComplete(true);
        }
    }, [setPaymentComplete]);

    const handleDateChange = useCallback((date) => {
        if (date.isSame(moment(), 'day'))
            setReturnReservationDate(minReservationDate());
        else
            setReturnReservationDate(date);

    }, []);
    const handleTimeChange = useCallback((time) => {
        if (!isTimeUnavailable(time))
            setReturnReservationDate(time);
    }, []);

    const save = () => {
        let suma = (parseInt(adultNumber) + parseInt(kidNumber) + 1);
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        let answers = {
            persona: {...persona, birthday: birthdayInput.date},
            comment,
            idTerminal: 'T1',
            acceso,
            suma,
            returnReservationDate,
            //selectedFlights,
            kidNumber,
            adultNumber
        };

        const resp = validateReservation(answers);

        if (resp.error)
            return toast(resp.error, {type: "error"});

        if (!emailPattern.test(persona.email))
            return toast('Agrega un email correcto');

        if (!formState.values['terms'])
            return toast('Debes aceptar los términos y condiciones para continuar.', {type: "error"});
        setLoading(true);

        uploadReservation(answers)
            .then((resp) => {
                setReservation(resp.data);
                setLoading(false);
            })
            .catch((err) => {

                if (err && err.response && err.response.data && err.response.data.detail)
                    toast(err.response.data.detail, {type: "error"});

                setLoading(false);
            })
    };
    /*const handleChangeFlight = (e) => {
        setSelectedFlights(e);
    };*/

    const handleChangeAccessMethod = (loungeAccessMethod) => {
        setAcceso(loungeAccessMethod);
    };

    const adultOptions=useMemo(()=>{
        const options=[];

        //if(acceso && acceso.maxAdults)
            for (let i = 0; i <= 4; i++){//Number(acceso.maxAdults); i++) {
                options.push({
                    value: i, label: i
                });
            }
        return options;

    },[]);

    return (
        <div>
            {paymentComplete ?
                <div className="thanks-payment">
                    <div className='alert alert-success'>
                        ¡Gracias por realizar tu pago!
                    </div>

                    <p>¡No olvides visitarnos en nuestras redes sociales!</p>
                    <div>
                        <a href="https://www.facebook.com/thegrandloungeelite/"><img
                            alt="Facebook"
                            className="social-icon"
                            src={Facebook}/></a>
                        <a href="https://twitter.com/GrandLoungeMex">
                            <img alt="Twitter"
                                 className="social-icon"
                                 src={Twitter}/>
                        </a>
                        <a href="https://www.instagram.com/thegrandloungeelite/">
                            <img alt="Instagram"
                                 className="social-icon"
                                 src={Instagram}/>
                        </a>
                    </div>
                    <div className="footer small text-muted">
                        <Footer/>
                    </div>

                </div> :
                <div>

                    {!reservation ? <div>
                            <div className="main-wrapper">
                                <ToastContainer/>
                                <div id="main-form">
                                    <h1 className="text-center">Reservaciones</h1>
                                    <p className="text-center welcome-text">
                                        Por favor ingresa tus datos para crear una reservación.
                                    </p>
                                    <label htmlFor="name">Nombre:</label>
                                    <input type="text" className="form-control" name="name" id="name"
                                           placeholder="Escribe tu nombre" required
                                           onChange={event => setPersona({...persona, name: event.target.value})}/>
                                    <label htmlFor="lastname">Apellido Paterno:</label>
                                    <input type="text" className="form-control" name="lastname" id="lastname"
                                           placeholder="Escribe tu apellido paterno" required
                                           onChange={event => setPersona({...persona, patLastName: event.target.value})}/>
                                    <label htmlFor="matLastName">Apellido Materno:</label>
                                    <input type="text" className="form-control" name="matLastName" id="matLastName"
                                           placeholder="Escribe tu apellido materno"
                                           onChange={event => setPersona({...persona, matLastName: event.target.value})}/>
                                    <label htmlFor="email">Email:</label>
                                    <input type="email" className="form-control" name="email" id="email"
                                           placeholder="Escribe tu email" required
                                           onChange={event => setPersona({...persona, email: event.target.value})}/>
                                    <label htmlFor="phone">Teléfono:</label>
                                    <input type="text" className="form-control" name="phone" id="phone"
                                           placeholder="Escribe tu teléfono (opcional)"
                                           onChange={event => setPersona({...persona, phone: event.target.value})}/>
                                    <label htmlFor="phone">Ciudad:</label>
                                    <input type="text" className="form-control" name="city" id="city"
                                           placeholder="Escribe tu ciudad (opcional)"
                                           onChange={event => setPersona({...persona, city: event.target.value})}/>
                                    {/*<label>Fecha de nacimiento:</label>
                                    <SingleDatePicker
                                        {...birthdayInput.props}
                                        hideKeyboardShortcutsPanel={true}
                                    />*/}
                                    <label htmlFor="name">Método de acceso:</label>
                                    <TideEntitySelect
                                        entity="lounge_access_methods"
                                        value={acceso}
                                        onChange={handleChangeAccessMethod}
                                        labelCreator={(a) => a.name}
                                        filterLocal
                                    />

                                    <div>
                                        <label htmlFor="numberAdults">Número de acompañantes:</label>
                                        <Select
                                            name="numberAdults"
                                            onChange={(event => setAdultNumber(event.value))}
                                            defaultValue={adult[0]}
                                            options={adultOptions}
                                        />
                                    </div>
                                    {/*
                                kids.length ?
                                    <div>
                                        <label htmlFor="numberKids">Número de niños</label>
                                        <Select
                                            name="numberKids"
                                            onChange={(event => setKidNumber(event.value))}
                                            defaultValue={kids[0]}
                                            options={kids}
                                        />
                                    </div>
                                    :
                                    null
                                    */
                                    }
                                    {/*
                            <label htmlFor="terminal">Terminal del aeropuerto:</label>
                            <Select
                                name="terminal"
                                onChange={(event => setIdTerminal(event.value))}
                                defaultValue={terminal[0]}
                                options={terminal}
                            />
                            */}


                                   {/*  <label htmlFor="flightNumber">Número de vuelo </label>
                                    <TideEntitySelect
                                        entity='flights'
                                        value={selectedFlights}
                                        filterBy={'search'}
                                        onChange={handleChangeFlight}
                                        labelCreator={f => `${f.airline.name} - ${f.number} - ${f.destination}`}

                                    />*/}
                                    <small id="emailHelp" className="form-text text-muted">No compartiremos tus datos con
                                        nadie
                                        más.</small>
                                    <br/>

                                    <div className="col-md-12">
                                        <DatePicker
                                            date={returnReservationDate}
                                            onDateChange={handleDateChange}
                                            focused={returnReservationDateFocus.focused}
                                            onFocusChange={setReturnReservationDateFocus}
                                            numberOfMonths={1}
                                            isOutsideRange={isDateUnavailable}
                                            hideKeyboardShortcutsPanel={true}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <TimePicker
                                            value={returnReservationDate}
                                            onChange={handleTimeChange}
                                        />

                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="notes">Comentarios adicionales:</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                <textarea name="notes" id="notes" className="form-control comments-area"
                                          placeholder="Te pedimos nos indiques la sala de tu preferencia y si quieres una experiencia o algún festejo durante tu estancia en nuestra sala."
                                          onChange={event => setComment(event.target.value)}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <input  {...checkbox('terms')}/>
                                        <label htmlFor="__ufs__terms">Acepto <span className="link"
                                                                                   onClick={() => setShowAviso(true)}>aviso de privacidad</span>
                                        </label>
                                    </div>
                                    <button disabled={loading} type="submit" className="btn btn-success"
                                            onClick={() => save()}> Enviar
                                    </button>
                                </div>

                            </div>
                            <div className="footer small text-muted">
                                <Footer/>
                            </div>
                        </div>
                        :
                        <ReservationSuccess
                            reservation={reservation}
                        />
                    }

                    <Terms
                        isOpen={showAviso}
                        onClose={() => setShowAviso(false)}
                    />
                </div>
            }
        </div>
    )
};
